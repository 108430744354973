import {IApiResponse, IData, ITicketTask} from "@/types"
import ApiService from "@/services/api_service"
import qs from "qs"

export default {
  getMyAccount(cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'edit_account'

      const params: IData = {
        action: actionName,
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('AccountService getMyAccount success', data)
        resolve(data)
      }).catch((data) => {
        console.log('AccountService getMyAccount error', data)
        reject(data)
      })
    })

  },

  saveBillingAddress(data: IData, cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'save_billing_addr'

      const params: IData = {
        ...data,
        action: actionName,
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId, {silent: true}).then((data) => {
        console.log('AccountService saveBillingAddress success', data)
        resolve(data)
      }).catch((data) => {
        console.log('AccountService saveBillingAddress error', data)
        reject(data)
      })
    })

  },


}
